import {mapGetters} from 'vuex';
import ProjectCardComponent from '@/views/projects/components/project-card/project-card.component.vue';
import TabsComponent from '@/components/shared/tabs/tabs.component.vue';
import TabComponent from '@/components/shared/tab/tab.component.vue';

export default {
    name: 'projects-component',
    components: {
        ProjectCardComponent,
        TabsComponent,
        TabComponent,
    },
    data() {
        return {
            selectTabId: 1,
            pageNumber: 1,
            pageSize: 15,
            projectStatus: true,
        };
    },
    computed: {
        ...mapGetters({
            locale: '_getLocal',
            projects: 'projects/_getProjects',
            pageCount: 'projects/_getPageCount'
        }),
        mainTabs() {
            return [
                {
                    title: this.locale?.projectsPage.completedProjects,
                    id: 1
                },
                {
                    title: this.locale?.projectsPage.ongoingProjects,
                    id: 2
                }
            ];
        }
    },
    methods: {
        selectTab(info) {
            this.selectTabId = info;
            if (this.selectTabId === 1) {
                this.projectStatus = true;
                this.$store.dispatch('projects/getProjects', {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    finished: this.projectStatus,
                });
            } else if (this.selectTabId === 2) {
                this.projectStatus = false;
                this.$store.dispatch('projects/getProjects', {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    finished: this.projectStatus,
                });
            }
        },
        changePage() {
            this.$store.dispatch('projects/getProjects', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                finished: this.projectStatus,
            });
        },
    },
    created() {
        this.$store.dispatch('projects/getProjects', {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            finished: this.projectStatus,
        });
    }

};
