<style src="./modal.component.scss" lang="scss"></style>
<template>
  <transition name="fadeIn">
    <div v-show="toggle" class="modal" @click.self="closeModal">
      <transition name="fade">
        <div v-show="toggle" class="modal__dialog  ">
          <div class="modal__content">
            <div class="modal__header">
              <button
                  @click="closeModal"
                  type="button"
                  class="modal__close"
              >
                <img src="@/assets/images/icons/modal-close.svg" alt="">
              </button>
            </div>
<!--            <div class="modal__body">-->
              <slot></slot>
<!--            </div>-->
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script src="./modal.component.js"></script>

