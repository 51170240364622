<style lang="scss" src="./projects.component.scss"></style>
<template>
  <div class="projects ">
    <div class="row">
      <div class="col-12">
        <tabs-component
            :tabs="mainTabs"
            @selectTab="selectTab">
          <tab-component
              :tab="{ name:`${locale?.projectsPage.completedProjects}`,id:1}"
              :selected="selectTabId">
            <div class="row">
              <div
                  v-for="item in projects"
                  :key="item.id"
                  class="col-12 col-md-6 col-lg-4">
                <project-card-component :project="item"/>
              </div>
            </div>
          </tab-component>
          <tab-component :tab="{
                       name:locale?.projectsPage.ongoingProjects,
                       id:2
                     }" :selected="selectTabId">
            <div class="row">
              <template v-for="item in projects">
                <div
                    :key="item.id"
                    class="col-12 col-md-6 col-lg-4" v-if="!item.finished">
                  <project-card-component :project="item"/>
                </div>
              </template>
            </div>
          </tab-component>
        </tabs-component>
      </div>
      <div class="col-12 mb-40" >
        <v-pagination
            v-if="pageCount>1"
            v-model="pageNumber"
            :pages="pageCount"
            :range-size="1"
            @update:modelValue="changePage"
        />
      </div>
    </div>
  </div>
</template>
<script src="./projects.component.js"></script>
