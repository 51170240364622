import {mapGetters} from 'vuex';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import ProjectDetailsCardComponent
    from '@/views/project-details/components/project-details-card/project-details-card.component.vue';
import ProjectDetailsInfoComponent
    from '@/views/project-details/components/project-details-info/project-details-info.component.vue';
import ModalComponent from '@/components/shared/modal/modal.component.vue';


SwiperCore.use([Navigation]);

export default {
    name: 'project-details-component',
    components: {
        ProjectDetailsCardComponent,
        ProjectDetailsInfoComponent,
        ModalComponent,
    },
    data() {
        return {
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                },
                // 1024 and up
                992: {
                    slidesPerView: 4,
                },
            },
            url: '',
            index: 0,

        };
    },
    computed: {
        ...mapGetters({
            project: 'projectDetails/_getProject',
            local: '_getLocal'
        }),
    },
    methods: {
        openModal(url, index) {
            this.url = url;
            this.index = index;
            this.$refs.modal.openModal();
        },
        prevImg() {
            if(this.index === 0){
                this.index = this.project.fileUrls.length;
                this.url = this.project.fileUrls[this.index];
            }
            if (this.index > 0) {
                this.index = this.index - 1;
                this.url = this.project.fileUrls[this.index];
            } else {
                this.index = this.project.fileUrls.length - 1;
                this.url = this.project.fileUrls[this.index];
            }
        },
        nextImg() {
            this.index = this.index + 1;
            this.index = this.index % this.project.fileUrls.length;
            this.url = this.project.fileUrls[this.index];
        }
    },
    created() {
        this.$store.dispatch('projectDetails/getProject', this.$route.params.id);
    }

};
