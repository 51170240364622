<style lang="scss" src="./project-card.component.scss"></style>
<template>
 <div class="project-card">
  <div class="project-card__wrapper">
    <div class="project-card__img">
      <img :src="project.fileUrl?.link" alt="">
      <div class="project-card__details">
        <router-link
            :to="{name:`project-details`, params:{
                id:project.id,
              } }"
        > {{ locale.projectsPage.details }}
        </router-link>
      </div>
    </div>
    <div class="project-card__description">
      {{project.name}}
    </div>
  </div>
 </div>
</template>
<script src="./project-card.component.js"></script>
