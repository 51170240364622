
export default {
    name: 'project-details-info-component',
    props: {
        img: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        }
    },
};
