export default {
    data() {
        return {
            toggle: false,
        };
    },
    methods: {
        openModal() {
            this.toggle = true;
            document.body.classList.add('modal-open');
        },
        closeModal() {
            this.toggle = false;
            document.body.classList.remove('modal-open');
        }
    }
};
