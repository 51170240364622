import {mapGetters} from 'vuex';

export default {
    name: 'project-details-card-component',
    props: {
        status: {
            type: Boolean,
        },
        customer: {
            type: String,
        },
        address: {
            type: String,
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            locale: '_getLocal'
        }),
        information() {
            return [
                {
                    id: 1,
                    title: this.locale.projectPage.projectStatus,
                    description: () => {
                        return this.status ? 'Tikintisi bitib' : 'Tikintisi davam edir';
                    },
                    icons: 'status.svg'
                },
                {
                    id: 2,
                    title: this.locale.projectPage.customer,
                    description: () => {
                        return this.customer;
                    },
                    icons: 'customer.svg'
                },
                {
                    id: 3,
                    title: this.locale.projectPage.address,
                    description: () => {
                        return this.address;
                    },
                    icons: 'address.svg'
                },

            ];
        }
    },

};
