<style lang="scss" src="./financial-report.component.scss"></style>
<template>
<div class="financial-report">
  <div class="financial-report__header">
    <info-component
        :title="leadFinancialReport.title"
        :description="leadFinancialReport.description"
        :img="leadFinancialReport.fileUrl"
    />
  </div>
  <div class="financial-report__content">
    <div class="row">
      <div class="col-lg-6 col-12"
           v-for="item in cards"
           :key="item.id">
        <card-component
            :information="item">
          {{ item.description }}
        </card-component>
      </div>
    </div>
  </div>
</div>
</template>
<script src="./financial-report.component.js"></script>
