<style lang="scss" src="./project-details.component.scss"></style>
<template>
  <project-details-card-component
      class="mt-60 mb-40"
      :address="project.address"
      :customer="project.customer"
      :status="project.finished"
  />
  <project-details-info-component
      :img="project.fileUrl.link"
      :title="project.title"
      :description="project.description"
  />
  <div class="slider" v-if="project.fileUrls?.length">
    <swiper
        :breakpoints="breakpoints"
        slides-per-view="4"
        :space-between="35"
        :navigation="{
                prevEl: '.slider__arrow--prev',
                nextEl: '.slider__arrow--next'
              }"
        spacebetween="35"
    >
      <div class="slider__wrapper">
        <div class="slider__arrow slider__arrow--prev">
          <img alt="left" src="@/assets/images/icons/arrow-left.svg">
        </div>
        <div class="slider__arrow slider__arrow--next">
          <img alt="right" src="@/assets/images/icons/arrow-right.svg">
        </div>
      </div>
      <swiper-slide
          v-for="(item, index) in project.fileUrls"
          :key="index"
          @click="openModal(item, index)"
      >
        <img  :src="item" alt="">
      </swiper-slide>
    </swiper>
  </div>
  <modal-component ref="modal">
    <div class="modal-gallery">
      <div class="modal-nav">
        <div class="slider__wrapper">
          <div class="slider__arrow slider__arrow--prev" @click="prevImg">
            <img alt="left" src="@/assets/images/icons/modal-left.svg">
          </div>
          <div class="slider__arrow slider__arrow--next"  @click="nextImg">
            <img alt="right" src="@/assets/images/icons/modal-right.svg">
          </div>
        </div>
      </div>
      <img class="img" :src="url" alt="">
    </div>
  </modal-component>
</template>
<script src="./project-details.component.js"></script>
