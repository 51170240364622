<style lang="scss" src="./contact.component.scss"></style>
<template>
  <div class="contact">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="contact__group d-block d-lg-none">
          <img
              v-for="(item, index) in iconLink"
              :key="index"
              :src="require(`@/assets/images/icons/${item}.svg`)"
              alt="icon"
          >
        </div>
        <div class="contact__group">
          <div class="contact-name">
           {{local.contactPage.email}}
          </div>
          <div class="contact-info">
            <a href="mailto:Info@diadem.az" class="mail">{{contacts.email}}</a>
            or <a class="orTel" :href="`tel:${contacts.phone}`" > Shcedule a call</a> and share your story with us
          </div>
        </div>
        <div class="contact__group">
          <div class="contact-name">
          {{local.contactPage.address}}
          </div>
          <div class="contact-info">
            <address>{{contacts.location}}</address>
          </div>
        </div>
        <div class="contact__group">
          <div class="contact-name">
            {{local.contactPage.phone}}
          </div>
          <div class="contact-info">
            <a :href="`tel:${contacts.phone}`" class="tel">{{contacts.phone}}</a>
          </div>
        </div>
        <div class="contact__group d-none d-lg-block">
          <img
              v-for="(item, index) in iconLink"
              :key="index"
              :src="require(`@/assets/images/icons/${item}.svg`)"
              alt="icon"
              class="mr-43"
          >
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="contact__banner img-banner">
          <iframe :src="locationURL" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./contact.component.js"></script>
