import {mapGetters} from 'vuex';
import CardComponent from "@/components/shared/card/card.component.vue";
import InfoComponent from "@/components/shared/info/info.component.vue";

export default {
    name: 'about-component',
    components: {
        CardComponent,
        InfoComponent
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            locale: '_getLocal',
            cards: 'financialReport/_getFinancialReport',
            leadFinancialReport: 'financialReport/_getLeadFinancialReport',
        }),
    },
    created() {
        this.$store.dispatch('financialReport/getFinancialReport');
    }

};
