import {mapGetters} from 'vuex';

export default {
    name: 'project-card-component',
    props: {
        project: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            locale: '_getLocal'
        }),
    },

};
