<style lang="scss" src="./project-details-card.component.scss"></style>
<template>
 <div class="project-details-card mb-40">
     <div class="project-details-card-item" v-for="item in information" :key="item.id">
       <div class="project-details-card-item__title mb-18"><img class="mr-15" :src="require(`@/assets/images/icons/${item.icons}`)" alt="">{{ item.title }}</div>
       <div class="project-details-card-item__description">{{ item.description() }}</div>
     </div>
   </div>
</template>
<script src="./project-details-card.component.js"></script>
