<style lang="scss" src="./project-details-info.component.scss"></style>
<template>
  <div class="project-details-info">
    <div class="project-details-info__img">
      <img :src="img" alt="">
    </div>
    <div class="project-details-info__text">
      <div class="project-details-info__title">
        {{ title }}
      </div>
      <div class="project-details-info__description">
        {{ description }}
      </div>
    </div>
  </div>
</template>
<script src="./project-details-info.component.js"></script>
